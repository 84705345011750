<template>
  <div class="page">
    <header class="header">我的收藏</header>
    <div class="main">
      <div class="goods-list">
        <div class="goods-item" v-for="(goods, key) in list" :key="key">
          <div :id="goods.id" class="goods-container" @click="goto(goods.goods_id)">
            <div
              class="goods-thumb"
              :style="'background-image: url(' + goods.thumb + ')'"
            ></div>
            <div class="goods-title">Lot {{goods.order_by}} {{ goods.name }}</div>
            <div class="goods-option">编号：{{goods.jigou}} {{goods.dengji}} {{ goods.sn }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang='scss' scord>
</style>

<script>
export default {
  data() {
    return {
      shop_id: null,
      list: [],
    };
  },

  async created() {
    localStorage.setItem('backTo', this.$route.fullPath);
    
    this.shop_id = this.$route.params.shop_id;
    const r = await this.$axios.get('/goods/getFavList?shop_id=' + this.shop_id);
    if(r.status == 1){
      this.list = r.list;
      console.log(this.list);
    }
  },

  methods: {
    goto(id) {
      this.$router.push("/goods/"+this.shop_id+"/" + id);
    },
  },
};
</script>